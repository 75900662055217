import { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from '@firebase/auth';

export default function AdminLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setError('Login failed: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full flex flex-row justify-center" style={{ backgroundColor: '#f6f6f6', minHeight: '100vh' }}>
      <div className="flex flex-col gap-y-[20px] relative font-normal jost w-full max-w-[1400px] px-4 sm:px-6 md:px-[5vw] pb-[0px]">
        <div className="h-[60px] sm:h-[80px] md:h-[100px]"></div>
        
        <div className="flex justify-between mb-12 sm:mb-16 md:mb-24">
          <div className="text-lg sm:text-xl">ADMIN LOGIN</div>
        </div>

        <div className="flex flex-col items-start gap-6 sm:gap-8">
          {error && <div className="text-lg sm:text-xl text-red-500 break-words max-w-full">{error}</div>}
          
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="EMAIL"
            className="text-lg sm:text-xl bg-transparent focus:outline-none w-full max-w-[200px]"
          />
          
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="PASSWORD"
            className="text-lg sm:text-xl bg-transparent focus:outline-none w-full max-w-[200px]"
          />
          
          <button
            onClick={handleLogin}
            className="text-lg sm:text-xl hover:opacity-60 transition-opacity"
            disabled={isLoading}
          >
            {isLoading ? (
              <span>
                LOGGING IN<span className="searching-dots"></span>
              </span>
            ) : (
              'LOGIN'
            )}
          </button>
        </div>
      </div>
    </div>
  );
}