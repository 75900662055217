import { useState, useEffect } from 'react';
import { ref, uploadBytes, listAll, getDownloadURL, deleteObject } from '@firebase/storage';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { storage } from '../firebase-config';
import AdminLogin from './AdminLogin';
import { trackDownload } from '../utils/downloads';

export default function AdminUpload() {
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const auth = getAuth();

  const adminEmails = [
    'admin@trnt.la',
    'edxcoin@gmail.com',
    'my@trnt.la',
    'upload@trnt.la'
  ];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        setIsAdmin(adminEmails.includes(user.email));
      } else {
        setIsAdmin(false);
      }
    });

    loadFiles();
    return () => unsubscribe();
  }, []);

  const loadFiles = async () => {
    try {
      const listRef = ref(storage, 'dl');
      const result = await listAll(listRef);
      
      const fileDetails = await Promise.all(
        result.items.map(async (item) => {
          const url = await getDownloadURL(item);
          return {
            name: item.name,
            url: url,
            path: item.fullPath
          };
        })
      );
      
      setFiles(fileDetails);
    } catch (error) {
      console.error('Error loading files:', error);
    }
  };

  const copyToClipboard = async (filename) => {
    try {
      const linkText = `${window.location.origin}/dl/${filename}`;
      await navigator.clipboard.writeText(linkText);
      alert('Link copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleUpload = async (event) => {
    if (!isAdmin) return;
    const file = event.target.files[0];
    if (!file) return;

    setUploading(true);
    const formattedName = `dl/${file.name}`;
    const storageRef = ref(storage, formattedName);

    // Set the Content-Disposition metadata to force download
    const metadata = {
      contentDisposition: `attachment; filename="${file.name}"`,
    };

    try {
      await uploadBytes(storageRef, file, metadata);
      await loadFiles();
      event.target.value = '';
    } catch (error) {
      console.error('Upload error:', error);
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async (filePath) => {
    if (!window.confirm('Are you sure you want to delete this file?')) return;
    
    try {
      const fileRef = ref(storage, filePath);
      await deleteObject(fileRef);
      await loadFiles();
    } catch (error) {
      console.error('Delete error:', error);
    }
  };

  const filteredFiles = files.filter(file => 
    file.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleDownload = async (file) => {
    try {
      // Step 1: Get fresh download URL
      const fileRef = ref(storage, file.path);
      const downloadURL = await getDownloadURL(fileRef);
      
      // Step 2: Fetch the file
      const response = await fetch(downloadURL);
      if (!response.ok) throw new Error('Download failed');
      
      // Step 3: Get the blob
      const blob = await response.blob();
      
      // Step 4: Create download link
      const blobUrl = window.URL.createObjectURL(blob);
      const downloadElement = document.createElement('a');
      downloadElement.style.display = 'none';
      downloadElement.href = blobUrl;
      downloadElement.download = file.name;
      
      // Step 5: Trigger download
      document.body.appendChild(downloadElement);
      downloadElement.click();
      
      // Step 6: Cleanup
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(blobUrl);
      
      // Step 7: Track download
      await trackDownload(user?.uid);
      
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  if (!user) {
    return <AdminLogin />;
  }

  if (!isAdmin) {
    return (
      <div className="w-full flex flex-row justify-center" style={{ backgroundColor: '#f6f6f6', minHeight: '100vh' }}>
        <div className="flex flex-col gap-y-[20px] relative font-normal jost w-full max-w-[1400px] px-[5vw] pb-[0px]">
          <div className="h-[100px]"></div>
          <div className="text-xl">Access denied. Not authorized.</div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-row justify-center" style={{ backgroundColor: '#f6f6f6', minHeight: '100vh' }}>
      <div className="flex flex-col gap-y-[20px] relative font-normal jost w-full max-w-[1400px] px-4 sm:px-6 md:px-[5vw] pb-[0px]">
        <div className="h-[60px] sm:h-[80px] md:h-[100px]"></div>
        
        <div className="flex justify-between items-center mb-12 sm:mb-16 md:mb-24">
          <div className="text-lg sm:text-xl">ADMIN UPLOAD</div>
          <button 
            onClick={() => auth.signOut()} 
            className="text-lg sm:text-xl hover:opacity-60 transition-opacity"
          >
            LOGOUT
          </button>
        </div>

        <div className="flex justify-between items-center mb-8 sm:mb-12 md:mb-16">
          <label className="text-lg sm:text-xl hover:opacity-60 transition-opacity cursor-pointer">
            SELECT FILES
            <input
              type="file"
              onChange={handleUpload}
              className="hidden"
              disabled={uploading}
            />
          </label>
          
          <div className="flex justify-end" style={{ minWidth: '120px', maxWidth: '200px' }}>
            <input
              type="text"
              placeholder="SEARCH"
              className="text-lg sm:text-xl bg-transparent focus:outline-none text-right w-full"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        {uploading && (
          <div className="text-lg sm:text-xl opacity-50 mb-4 sm:mb-6 md:mb-8">
            <span>Uploading</span>
            <span className="searching-dots"></span>
          </div>
        )}

        <div className="flex flex-col gap-4">
          {filteredFiles.map((file, index) => (
            <div key={index} className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 sm:gap-0">
              <span className="text-lg sm:text-xl break-all sm:break-normal sm:max-w-[40%]">{file.name}</span>
              <div className="flex flex-wrap gap-4 sm:gap-8">
                <button
                  onClick={() => handleDownload(file)}
                  className="text-lg sm:text-xl hover:opacity-60 transition-opacity"
                >
                  DOWNLOAD
                </button>
                <button
                  onClick={() => copyToClipboard(file.name.replace('dl/', ''))}
                  className="text-lg sm:text-xl hover:opacity-60 transition-opacity"
                >
                  COPY LINK
                </button>
                <button
                  onClick={() => handleDelete(file.path)}
                  className="text-lg sm:text-xl hover:opacity-60 transition-opacity text-red-500"
                >
                  DELETE
                </button>
              </div>
            </div>
          ))}

          {!uploading && filteredFiles.length === 0 && (
            <div className="text-lg sm:text-xl opacity-50">
              {searchQuery ? 'No matching files found' : 'No files uploaded'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}