import { BrowserRouter, Route, Routes } from "react-router-dom";
import Welcome from "./pages/Welcome";
import DCLeak from './pages/DCLeak';
import AdminUpload from './components/AdminUpload';
import Downloads from './components/Downloads';
import { getDownloadURL, ref } from '@firebase/storage';
import { storage } from './firebase-config';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import FTPInstructions from './components/FTPInstructions';

export default function Router() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Welcome />} />
                <Route path="/dcleak" element={<DCLeak />} />
                <Route path="/portal" element={<AdminUpload />} />
                <Route path="/downloads" element={<Downloads />} />
                <Route path="/dl/:filename" element={<FileDownload />} />
                <Route path="/ftp-setup" element={<FTPInstructions />} />
            </Routes>
        </BrowserRouter>
    )
}

function FileDownload() {
  const { filename } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getFile = async () => {
      try {
        const fileRef = ref(storage, `dl/${filename}`);
        const url = await getDownloadURL(fileRef);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (error) {
        console.error('Download error:', error);
        navigate('/downloads');
      }
    };

    getFile();
  }, [filename, navigate]);

  return <div className="text-xl">Starting download...</div>;
}