import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';

export default function FTPInstructions() {
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const bucketName = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;

  useEffect(() => {
    const getToken = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          setError('Please login first');
          return;
        }
        const idToken = await user.getIdToken();
        setToken(idToken);
      } catch (err) {
        setError('Failed to get access token');
      }
    };
    getToken();
  }, []);

  return (
    <div className="w-full flex flex-row justify-center" style={{ backgroundColor: '#f6f6f6', minHeight: '100vh' }}>
      <div className="flex flex-col gap-y-[20px] relative font-normal jost w-full max-w-[1400px] px-4 sm:px-6 md:px-[5vw] pb-[0px]">
        <div className="h-[60px] sm:h-[80px] md:h-[100px]"></div>
        
        <div className="text-lg sm:text-xl mb-12">FTP ACCESS</div>

        {error ? (
          <div className="text-red-500">{error}</div>
        ) : (
          <div className="space-y-6">
            <div className="space-y-4">
              <h3 className="text-lg font-medium">FileZilla Connection Settings:</h3>
              
              <div>
                <div className="font-medium">Host:</div>
                <code className="block bg-gray-100 p-2 rounded">storage.googleapis.com</code>
              </div>

              <div>
                <div className="font-medium">Protocol:</div>
                <code className="block bg-gray-100 p-2 rounded">HTTPS</code>
              </div>

              <div>
                <div className="font-medium">Port:</div>
                <code className="block bg-gray-100 p-2 rounded">443</code>
              </div>

              <div>
                <div className="font-medium">Path:</div>
                <code className="block bg-gray-100 p-2 rounded">/storage/v1/b/{bucketName}/o/dl</code>
              </div>

              <div>
                <div className="font-medium">Access Token (use as password):</div>
                <code className="block bg-gray-100 p-2 rounded break-all">{token}</code>
              </div>
            </div>

            <div className="mt-8 space-y-2">
              <h3 className="text-lg font-medium">Steps:</h3>
              <ol className="list-decimal list-inside space-y-2">
                <li>Open FileZilla</li>
                <li>Go to File → Site Manager</li>
                <li>Click "New Site"</li>
                <li>Enter the settings above</li>
                <li>Leave username blank</li>
                <li>Use the Access Token as your password</li>
                <li>Click "Connect"</li>
              </ol>
            </div>

            <div className="mt-4 text-sm text-gray-600">
              Note: Access token expires after 1 hour. Refresh this page to get a new token.
            </div>
          </div>
        )}
      </div>
    </div>
  );
}