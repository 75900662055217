import React from 'react';

export default function DCLeak() {
  return (
    <div className="w-full min-h-screen bg-[#f6f6f6] flex items-center justify-center">
      <div className="max-w-[600px] mx-auto px-4 text-center">
        <h1 className="text-2xl sm:text-3xl mb-4">
          Maintenance
        </h1>
        <div className="text-lg sm:text-xl opacity-70">
          This page is temporarily unavailable.
        </div>
      </div>
    </div>
  );
}