import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import Router from "./Router";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<Router />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Simple rate limiting implementation
const rateLimit = {
  requests: {},
  limit: 100, // requests per window
  windowMs: 15 * 60 * 1000, // 15 minutes
  
  checkLimit: function(ip) {
    const now = Date.now();
    if (!this.requests[ip]) {
      this.requests[ip] = {
        count: 1,
        windowStart: now
      };
      return true;
    }

    if (now - this.requests[ip].windowStart > this.windowMs) {
      // Reset window
      this.requests[ip] = {
        count: 1,
        windowStart: now
      };
      return true;
    }

    if (this.requests[ip].count >= this.limit) {
      return false; // Rate limit exceeded
    }

    this.requests[ip].count++;
    return true;
  }
};

// Apply to fetch requests
const originalFetch = window.fetch;
window.fetch = function(...args) {
  const clientIP = window.clientIP || 'unknown'; // You'll need to set this
  if (!rateLimit.checkLimit(clientIP)) {
    return Promise.reject(new Error('Rate limit exceeded'));
  }
  return originalFetch.apply(this, args);
};

// Basic request throttling
let lastRequestTime = 0;
const minRequestInterval = 100; // milliseconds

document.addEventListener('click', (e) => {
  const now = Date.now();
  if (now - lastRequestTime < minRequestInterval) {
    e.preventDefault();
    return;
  }
  lastRequestTime = now;
});
